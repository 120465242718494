import * as React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Board from '../../components/snake/Board/Board.jsx';

// markup
const IndexPage = ({ data }) => {
    return (
        <main>
            <Header />
            <div className="container px-5 lg:py-12 sm:py-0 mx-auto flex flex-col">
                <div className="lg:8 mx-auto">
                    <Board />
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default IndexPage;
